import NextLink from "next/link";
import { useTranslation } from "next-i18next";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Section } from "@atoms/Section";
import Text from "@ds/components/Text";
import Button from "@ds/components/Button";
import type { MediaInfo } from "@libraries/services/interface";
import useStyles from "../styles";
import { IMG_WHATSAPP } from "@config/images";
import getAdminWA from "@helpers/getAdminWA";
import DynamicImage from "@ds/components/DynamicImage";
import { gtmCustomEvent } from "@helpers/analytics";
import { isAllowInterest } from "@config/feature";

type BusinessSolutionSectionProps = {
  backgroundLead: MediaInfo;
};

const BusinessSolutionSection: React.VFC<BusinessSolutionSectionProps> = ({ backgroundLead }) => {
  const classes = useStyles();
  const { t } = useTranslation("common");

  const onWAClick = () => {
    const timeoutFetch = setTimeout(() => {
      gtmCustomEvent(
        {
          eventAction: `Klik WA - homepage`,
          eventCategory: "Klik WA",
          eventLabel: `Klik WA - homepage`,
        },
        "LeadProduct",
      );
    }, 1000);
    window.open(getAdminWA(), "_blank");
    return () => window.clearTimeout(timeoutFetch);
  };

  return (
    <>
      <Section paddingY="60px" maxWidth="full-width" px="110px">
        <Container maxWidth="lg" disableGutters>
          <Box
            textAlign="center"
            paddingY="60px"
            borderRadius="16px"
            className={classes.gainAccess}
            sx={{
              backgroundImage: `
                linear-gradient(rgba(0,0,0,60%),rgba(0,0,0,60%)),
                url("${backgroundLead?.mediaPath}")`,
            }}
          >
            <Box maxWidth={680} margin="auto">
              <Text className={classes.titleReguler} variant="h2" color="white" weight="700">
                {t("interest.title")}
              </Text>
              <Text component="p" variant="body1" color="white" sx={{ marginBottom: 20 }}>
                {t("interest.desc")}
              </Text>
            </Box>
            <Box className={classes.interestButtonContainer}>
              <Button
                onClick={onWAClick}
                size="large"
                variant="outlined"
                width="230px"
                className={classes.whatsAppButton}
                startIcon={
                  <DynamicImage
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={20}
                    height={20}
                    src={IMG_WHATSAPP}
                    alt="whatsapp"
                  />
                }
              >
                {t("interest.whatsapp")}
              </Button>
              {isAllowInterest && (
                <NextLink href="/interest-form">
                  <a>
                    <Button size="large" width="230px">
                      {t("interest.button")}
                    </Button>
                  </a>
                </NextLink>
              )}
            </Box>
          </Box>
        </Container>
      </Section>
    </>
  );
};

export default BusinessSolutionSection;
